<template>
    <div class="modal-wrapper"
         :class="{hide: loggedIn || modals.register || modals.newPassword || modals.forgotPassword, newDesign: newDesign}">
        <div class="modal" :class="{hide: modals.otp}">
            <div class="logo" :style="{display: hideLogo ? 'none' : ''}">
                <img :src="logoComputed" :style="{height: logo.height, opacity: hideLogo ? 0 : 1}" :class="{hide: !logoComputed}" @error="errorHandler"/>
            </div>
            <div class="title">
                <i v-if="newDesign" class="material-icons">person_outline</i>
                <span>{{ tokens.loginModal.loginToTradeRoom.toUpperCase() }}</span>
                <i v-if="!newDesign" class="material-icons">lock</i>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-row">
                        <label>{{ ['astroglobal365','vergoblockchain'].includes(brand) ? tokens.loginModal.emailAndId : tokens.loginModal.email }}</label>
                        <input class="form-control" name="email" autocomplete="off" tabindex="1" v-model="email"
                               :placeholder="tokens.loginModal.enterEmail" @keyup.enter="login"/>
                    </div>
                    <div v-if="!newDesign && !hide2FA" class="form-row">
                        <label>{{ tokens.loginModal.twoFactor }}</label>
                        <input class="form-control pass" name="twoFactorAuth" autocomplete="off" tabindex="3" v-model="otp"
                               :placeholder="tokens.loginModal.twoFactorAuth" @keyup.enter="login"/>
                    </div>
                    <div v-else class="form-row" style="height:100%;"></div>
                    <div v-if="!hideForgotPassword && !newDesign" class="form-row">
                        <span class="forgot pointer" @click="showForgot">{{
                                tokens.forgotPasswordModal.forgotPassword
                            }}</span>
                    </div>
                </div>
                <div class="col">
                    <div class="form-row">
                        <label>{{ tokens.loginModal.password }}</label>
                        <div class="show-hide-password">
                            <input class="form-control pass" ref="password" name="password" :type="passwordFieldType" autocomplete="off" tabindex="2"
                                   v-model="password" :placeholder="tokens.loginModal.yourPassword" @keyup.enter="login"/>
                            <i class="material-icons"
                               @touchstart="showPass($refs.password)"
                               @touchend="hidePass($refs.password)"
                               @touchmove="hidePass($refs.password)"
                               @mousedown="showPass($refs.password)"
                               @mouseup="hidePass($refs.password)"
                               @dragover="hidePass($refs.password)"
                               @mouseout="hidePass($refs.password)">visibility</i>
                        </div>
                    </div>
                    <div class="form-row" :class="{last: newDesign}">
                        <label>&nbsp;</label>
                        <div class="button green-button" v-on:click="login" style="margin-top: 0">
                            {{ tokens.loginModal.signIn.toUpperCase() }}
                        </div>
                    </div>
                    <div v-if="showToRegisterButton" class="form-row register">
                        <p v-if="!showRegisterModal">
                            {{ tokens.loginModal.dontHaveAccount }}
                            <a href="/register">{{ tokens.fullScreenReferrals.registered }}</a>
                        </p>
                    </div>
                    <div v-if="!hideForgotPassword && newDesign" class="form-row footer">
                        <p>
                            {{
                                tokens.forgotPasswordModal.forgotPassword
                            }}
                            <span @click="showForgot">{{ tokens.loginModal.restore }}</span>
                        </p>
                        <p v-if="!showRegisterModal">
                            {{ tokens.loginModal.dontHaveAccount }}
                            <a href="/register">{{ tokens.fullScreenReferrals.registered }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <TwoFactorAuthenticationModal v-if="newDesign" :class="{hide: !modals.otp}" :email="this.email" :password="this.password"/>
    </div>
</template>

<script>
import {HIDE_2FA, HIDE_FORGOT_PASSWORD, NEW_DESIGN, SHOW_REGISTER_MODAL} from "@/common/config";

import {mapGetters} from 'vuex'
import {LOGIN, PRE_LOGIN} from "@/store/actions.type";
import {MODAL} from "@/store/mutations.type";
import TwoFactorAuthenticationModal from "@/components/Modals/TwoFactorAuthenticationModal";
import {hidePass, showPass} from "@/common/helpers";
import {BRAND,LOGO , HIDE_LOGO, WHITE_THEME,SHOW_TO_REGISTER_BUTTON} from "@/common/config"

export default {
    name: "LoginModal",
    components: {
        TwoFactorAuthenticationModal
    },
    data() {
        return {
            newDesign: NEW_DESIGN,
            hideForgotPassword: HIDE_FORGOT_PASSWORD,
            email: '',
            password: '',
            otp: '',
            passwordFieldType: 'password',
            showRegisterModal: SHOW_REGISTER_MODAL,
            hide2FA: HIDE_2FA,
            brand: BRAND,
            logo: LOGO,
            hideLogo: HIDE_LOGO,
            whiteTheme: WHITE_THEME,
            showToRegisterButton: SHOW_TO_REGISTER_BUTTON
        }
    },
    methods: {
        showPass,
        hidePass,
        login() {
            this.newDesign ?
                this.$store.dispatch(PRE_LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                }) : this.$store.dispatch(LOGIN, {
                    email: this.email.trim(),
                    password: this.password.trim(),
                    otp: this.otp
                })
        },
        showForgot() {
            this.$store.commit(MODAL, {
                forgotPassword: true
            })
        },
        errorHandler(){
            this.logo.url = ''
            this.hideLogo = 1
        },
    },
    computed: {
        ...mapGetters(['loggedIn', 'modals', 'tokens','lightTheme']),
        logoComputed() {
            let url = this.logo.url

            if(this.whiteTheme){
                return this.lightTheme ? url.replace(/white/, 'black') : url
            }else{
                return this.tradingViewTheme === 'Light' ? url.replace(/white/, 'black') : url
            }
        }
    },
    watch: {
        loggedIn(val) {
            if (val) {
                this.email = ''
                this.password = ''
                this.otp = ''
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper,
.modal-otp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.newDesign .row {
    margin-bottom: 10px;
    flex-direction: column;
}

.newDesign .row .col {
    margin-right: 0;
}

.newDesign .last{
    margin-top: -20px;
}

.newDesign .modal-otp .modal {
    width: 400px;
}

.newDesign .modal-otp .modal label {
    color: var(--white);
}

.newDesign .modal {
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(151.15deg, rgba(155, 162, 187, 0.85) 1.95%, rgba(57, 57, 57, 0.85) 82.08%);
    border-radius: 10px;
    padding: 30px 40px;
    border: 1px solid #CECECE;
    z-index: 1100;
}

.newDesign .form-row {
    margin-bottom: 20px;
}

.newDesign .form-init {
    width: 100%;
    margin-bottom: 10px;
}

.newDesign ::placeholder {
    color: transparent;
}

.newDesign .title {
    justify-content: flex-start;
    gap: 5px;
}

.newDesign label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.newDesign input {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.newDesign .button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    cursor: pointer;
    background: #247243;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
}

.newDesign .footer {
    align-items: center;
}

.newDesign .footer p {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.6);
}

.newDesign .footer p:first-child {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
}

.newDesign .footer p:last-child {
    margin-bottom: 0;
    text-align: center;
}

.newDesign .footer span:last-child,
.newDesign .footer p a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--white);
    margin-left: 5px;
    cursor: pointer;
}


.modal {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.logo{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        max-width: 100%;
        max-height: 80px;
        object-fit: contain;
        object-position: center;
    }
}

.title {
    color: var(--white-to-black);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input {
    width: 100%;
}

.row {
    overflow: hidden;
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

.forgot {
    border-bottom: 1px dashed var(--font);
    font-size: 11.3px;
}

.register{
    font-size: 11.3px;
}
.register a{
    border-bottom: 1px dashed var(--font);
    color: var(--font);
    text-decoration: none;
}

</style>
