<template>
    <div class="footer">
        <div class="row">
            <i class="nomobile material-icons">tty</i>
            <span class="nomobile support-email">{{supportEmail}}</span>
            <span class="nomobile">{{schedule ? schedule : tokens.footer.supportBanner }}</span>
            <span class="deposit" @click="showCabinetPage('deposit')" v-show="isMobile">{{tokens.fullScreen.deposit.toUpperCase()}}</span>
        </div>
        <div class="row pads">
            <div class="row live-chat" :class="{blinking: unreadMessageCounter !== '0' && supportUnread}" v-if="user.enable_chat && !hideLiveChat" @click="showCabinetPage('support')">
                <i class="material-icons">mail_outline</i>
                <span>{{ tokens.footer.liveChat.toUpperCase() }} {{unreadMessageCounter === '0' || !supportUnread ? '' : `(${unreadMessageCounter})`}}</span>
            </div>
            <i class="material-icons" @click="toggleMute">{{mute ? 'volume_off' : 'volume_up'}}</i>
            <i class="material-icons" @click="showCabinetPage('settings')">settings</i>
            <span class="nomobile">{{ tokens.footer.currentTime }}: {{ brand !== 'jpy500' ?  new Date(time).toLocaleString() : new Date(time).toUTCString()  }}</span>
            <i class="material-icons" @click="fullscreen">fullscreen</i>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {SUPPORT_EMAIL, SCHEDULE, BRAND, HIDE_LIVE_CHAT} from "@/common/config";
    import {requestFullScreen, isMobile} from "@/common/helpers";
    import {MODAL, MODAL_FULLSCREEN, MUTE} from "@/store/mutations.type";

    export default {
        name: "Footer",
        mounted() {
            setInterval(() => {
                this.time = Date.now()
            }, 1000)
        },
        data() {
            return {
                supportEmail: SUPPORT_EMAIL,
                schedule: SCHEDULE,
                time: 0,
                isMobile: isMobile(),
                unreadMessageCounter: '0',
                brand: BRAND,
                hideLiveChat: HIDE_LIVE_CHAT
            }
        },
        methods: {
            fullscreen() {
                requestFullScreen(document.body)
            },
            toggleMute() {
                this.$store.commit(MUTE, !this.mute)
            },
            showCabinetPage(page) {
                this.$store.commit(MODAL, {
                    fullscreen: true
                })

                this.$store.commit(MODAL, {
                    hamburgerMenu: false
                })

                this.$store.commit(MODAL_FULLSCREEN, {
                    page
                })
            }
        },
        computed: {
            ...mapGetters(['mute', 'user', 'tokens', 'supportMessages', 'modalFullscreen','supportUnread'])
        },
        watch: {
            supportMessages:{
                deep: true,
                handler(newVal){
                    if (newVal) {
                        let messageCounter = '0'
                        for (const n in newVal) {
                            if ((!newVal[n].time_read || newVal[n].time_read === 0)) {
                                ++messageCounter
                                if (messageCounter > 100) {
                                    this.$set(this, 'unreadMessageCounter', '99+')
                                    break
                                }
                            }
                        }

                        this.$set(this, 'unreadMessageCounter', messageCounter)
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .footer {
        flex: 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--background-footer);
        padding: 0 15px;
        font-weight: 400;
        border-top: 1px solid var(--border);
    }

    i {
        font-size: 18px;
    }

    .support-email {
        margin: 0 15px 0 0;
        color: var(--white-to-black)
    }

    .pads > * {
        margin-right: 15px;
    }

    .pads > *:last-child {
        margin-right: 0;
    }

    .pads i {
        cursor: pointer;
    }

    .live-chat {
        color: var(--white-to-black);
        cursor: pointer;
    }

    .live-chat i {
        margin-right: 5px;
    }

    .blinking {
        animation: blinker 3s linear infinite
    }

    .deposit{
        color: var(--white-to-black);
        margin-left: 10px;
    }

    @keyframes blinker {
        50% {
            color: var(--font);
        }
    }

    @media (max-width: 1000px) {
        .row{
            margin-bottom: 0;
        }
    }
</style>
